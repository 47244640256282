export default {
  color: `omegaDark`,
  textDecoration: `none`,
  userSelect: `none`,
  ':visited': {
    color: 'omegaDark'
  },
  ':hover': {
    color: 'alphaDark'
  },
  ':active': {
    color: 'alphaDark'
  }
}
